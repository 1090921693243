// 根据不同的环境设定不同的baseUrl

let appUrl // 本地代理
let baseUrl
let operationCenterUrl
let baseServeUrl
let adminUrl
let mallUrl
let supplierUrl
let enterpriseUrl
let selectionUrl
let ocUrl
let o2oUrl
let fileUrl = 'https://yzh-user.oss-cn-hangzhou.aliyuncs.com'
let queryFileUrl
let dataCenterUrl
let biUrl
let aiUrl
let env = process.env.REACT_APP_ENV

if (env === 'development') env = 'test' /* 需要调试的环境 */

switch (env) {
    case 'dev':
        selectionUrl = 'http://selection-test.yunzhonghe.com'
        ocUrl = 'http://oc-test.haoxiny.com'
        o2oUrl = 'https://o2o-admin-test.haoxiny.com'
        supplierUrl = 'http://proj-supplier.test.limofang.cn'
        baseServeUrl = 'http://192.168.1.194:2003'
        enterpriseUrl = 'http://ec-test.haoxiny.com'
        adminUrl = 'http://admin-test.haoxiny.com'
        mallUrl = 'http://pc-test.haoxiny.com'
        appUrl = baseServeUrl + '/api' // 测试环境url
        baseUrl = appUrl + '/user' // 测试环境url
        operationCenterUrl = appUrl + '/operation' // 商品运营中心
        fileUrl += '/template_test'
        queryFileUrl = 'https://file-test.yunzhonghe.com'
        dataCenterUrl = 'http://116.205.237.33:28080/'
        break
    case 'test':
        selectionUrl = 'http://selection-test.yunzhonghe.com'
        ocUrl = 'http://oc-test.haoxiny.com'
        o2oUrl = 'https://o2o-admin-test.haoxiny.com'
        supplierUrl = 'http://proj-supplier.test.limofang.cn'
        baseServeUrl = 'http://gateway.test.limofang.cn'
        enterpriseUrl = 'http://ec-test.haoxiny.com'
        adminUrl = 'http://admin-test.haoxiny.com'
        mallUrl = 'http://pc-test.haoxiny.com'
        appUrl = baseServeUrl + '/api' // 测试环境url
        // appUrl = '/api' // 测试环境url
        baseUrl = appUrl + '/user' // 测试环境url
        operationCenterUrl = appUrl + '/operation' // 商品运营中心
        fileUrl += '/template_test'
        queryFileUrl = 'https://file-test.yunzhonghe.com'
        dataCenterUrl = 'http://116.205.237.33:28080/'
        break
    case 'jindie':
        selectionUrl = 'http://selection-jindie.yunzhonghe.com'
        ocUrl = 'http://oc-jindie.haoxiny.com'
        o2oUrl = 'https://o2o-admin-jindie.haoxiny.com'
        supplierUrl = 'http://srm.jindie.limofang.cn'
        baseServeUrl = 'http://192.168.1.235:2003'
        enterpriseUrl = 'http://ec-jindie.haoxiny.com'
        adminUrl = 'http://admin-jindie.haoxiny.com'
        mallUrl = 'http://pc-jindie.haoxiny.com'
        appUrl = baseServeUrl + '/api' // 测试环境url
        baseUrl = appUrl + '/user' // 测试环境url
        operationCenterUrl = appUrl + '/operation' // 商品运营中心
        fileUrl += '/template_test'
        queryFileUrl = 'https://file-test.yunzhonghe.com'
        dataCenterUrl = 'http://116.205.237.33:28080'
        break
    case 'jindie-hd':
        selectionUrl = 'http://selection-jindie-hd.yunzhonghe.com'
        ocUrl = 'http://oc-jindie-hd.haoxiny.com'
        o2oUrl = 'https://o2o-admin-jindie-hd.haoxiny.com'
        supplierUrl = 'http://srm.jindie.limofang.cn'
        baseServeUrl = 'http://192.168.1.112:2003'
        enterpriseUrl = 'http://ec-jindie-hd.haoxiny.com'
        adminUrl = 'http://admin-jindie-hd.haoxiny.com'
        mallUrl = 'http://pc-jindie-hd.haoxiny.com'
        appUrl = baseServeUrl + '/api' // 测试环境url
        baseUrl = appUrl + '/user' // 测试环境url
        operationCenterUrl = appUrl + '/operation' // 商品运营中心
        fileUrl += '/template_test'
        queryFileUrl = 'https://file-test.yunzhonghe.com'
        dataCenterUrl = 'http://116.205.237.33:28080'
        break
    case 'show':
        selectionUrl = 'https://selection-show.yunzhonghe.com'
        o2oUrl = 'https://o2o-admin-show.haoxiny.com'
        supplierUrl = 'https://ld-supplier-show.yunzhonghe.com'
        ocUrl = 'https://oc-show.haoxiny.com'
        enterpriseUrl = 'https://ec-show.haoxiny.com'
        adminUrl = 'https://admin-show.haoxiny.com'
        mallUrl = 'https://pc-show.haoxiny.com'
        baseServeUrl = 'https://gateway-show.yunzhonghe.com'
        appUrl = baseServeUrl + '/api' // aliyun预演环境url
        baseUrl = appUrl + '/user' //aliyun生产环境url
        operationCenterUrl = appUrl + '/operation' // 商品运营中心
        fileUrl += '/template_test'
        queryFileUrl = 'https://file-show.yunzhonghe.com'
        dataCenterUrl = 'https://bigdata-show.yunzhonghe.com'
        biUrl = 'http://bigdata-bi-show.yunzhonghe.com/#/workbranch/index'
        aiUrl = 'http://ai-show.yunzhonghe.com'
        break
    case 'production':
        selectionUrl = 'https://selection.yunzhonghe.com'
        ocUrl = 'https://oc.haoxiny.com'
        o2oUrl = 'https://o2o-admin.haoxiny.com'
        supplierUrl = 'https://srm.yunzhonghe.com'
        enterpriseUrl = 'https://ec.haoxiny.com'
        adminUrl = 'https://admin.haoxiny.com'
        mallUrl = 'https://pc.haoxiny.com'
        baseServeUrl = 'https://gateway.yunzhonghe.com'
        appUrl = baseServeUrl + '/api' // aliyun生产环境url
        baseUrl = appUrl + '/user' // aliyun生产环境url
        operationCenterUrl = appUrl + '/operation' // 商品运营中心
        fileUrl += '/template'
        queryFileUrl = 'https://file.yunzhonghe.com'
        dataCenterUrl = 'https://bigdata.yunzhonghe.com'
        biUrl = 'https://bigdata-bi.yunzhonghe.com/#/workbranch/index'
        aiUrl = 'http://ai.yunzhonghe.com'
        break
    case 'ipo':
        selectionUrl = 'https://selection.yunzhmall.com'
        ocUrl = 'https://oc.haoxiny.com'
        o2oUrl = 'https://o2o-admin.haoxiny.com'
        supplierUrl = 'https://srm.yunzhmall.com'
        enterpriseUrl = 'https://ec.haoxiny.com'
        adminUrl = 'https://admin.haoxiny.com'
        mallUrl = 'https://pc.haoxiny.com'
        baseServeUrl = 'https://gateway.yunzhmall.com'
        appUrl = baseServeUrl + '/api' // aliyun生产环境url
        baseUrl = appUrl + '/user' // aliyun生产环境url
        operationCenterUrl = appUrl + '/operation' // 商品运营中心
        fileUrl += '/template'
        queryFileUrl = 'https://file.yunzhmall.com'
        dataCenterUrl = 'https://bigdata.yunzhonghe.com'
        biUrl = 'https://bigdata-bi.yunzhonghe.com/#/workbranch/index'
        break
}
export default baseUrl
export {
    selectionUrl,
    supplierUrl,
    baseUrl,
    enterpriseUrl,
    adminUrl,
    mallUrl,
    operationCenterUrl,
    baseServeUrl,
    appUrl,
    fileUrl,
    ocUrl,
    o2oUrl,
    queryFileUrl,
    dataCenterUrl,
    biUrl,
    aiUrl
}
